import React, { useState } from "react";
import { Loader } from "google-maps";

const SmartMap = ({
  policyNo,
  lat,
  lng,
  zoom = 12,
  apiKey,
}) => {

  const createMapOptions = (maps) => {
    return {
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: false,
      fullscreenControl: true
    };
  };

  const [dynamicMap, setDynamicMap] = useState(false);

  const staticMapUrl = `/staticmap/2/${zoom-1}/${policyNo}.png`;

  const createDynamicMap = async () => {
    setDynamicMap(true);
    const options = {/* todo */};
    const loader = new Loader(apiKey, options);
    const localGoogleVar = await loader.load();
    const map = new localGoogleVar.maps.Map(document.getElementById('map'), {
      center: { lat, lng },
      zoom,
      mapId: '8041c9b566fb264'
    });
    new localGoogleVar.maps.Marker(
      {
        map: map,
        position: new localGoogleVar.maps.LatLng(lat, lng)
      }
    );
  };

  return (
    <div
      id={"map"}
      className={"tw-hidden lg:tw-block tw-ml-4 tw-shadow tw-overflow-hidden tw-rounded-xl tw-h-full"}
    >
      {!dynamicMap &&
                <img
                  onClick={createDynamicMap}
                  src={staticMapUrl}
                  className={"tw-w-full tw-h-full tw-cursor-pointer tw-object-fill"}
                  alt="Static map preview"
                />
      }
    </div>
  );
};

export default SmartMap;