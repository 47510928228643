import { ApiCore } from "./core";
import { apiProvider } from "./core/provider";

const url = "inspectors";

const apiInspectors = new ApiCore({
  list: false,
  get: true,
  post: false,
  put: false,
  patch: false,
  delete: false,
  url: url,
});

apiInspectors.getLocation = (id) => {
  return apiProvider.get(`inspectors/${id}/location`); 
};

export { apiInspectors };
